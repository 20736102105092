import _ from "lodash"
import React from "react"
import { useMediaQuery } from "react-responsive"
import marked from "marked"
import { Carousel } from "react-responsive-carousel"
import { parseRawHtml as parse } from "../utils"

import {
  Container,
  Header,
  Image,
  Segment,
  Card,
  Modal,
  Button,
  Grid,
  Responsive,
} from "semantic-ui-react"
import ResponsiveLayout from "../components/responsive-layout"
import SocialShare from "../components/social-share";
import "../components/styles.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import PageContent from "frontmatter-markdown-loader?modules!../../content/home.md"


export default ({ data = PageContent.attributes }) => (
  <ResponsiveLayout title={data.title}>
    <Page content={data} />
  </ResponsiveLayout>
)

const Page = ({ content, isMobile }) => {
  const isMobileSpecifically = useMediaQuery({
    maxWidth: Responsive.onlyMobile.maxWidth
  })
  const isTabletSpecifically = useMediaQuery({
    minWidth: Responsive.onlyTablet.minWidth,
    maxWidth: Responsive.onlyTablet.maxWidth,
  })
  const isComputerSpecifically = useMediaQuery({
    minWidth: Responsive.onlyTablet.maxWidth
  })
  return (
    <>
      {
        isComputerSpecifically &&
        <Segment basic
          style={{
            backgroundImage: `url(${content.heroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "46em",
            margin: "0em",
          }}
          children={
            <Container>
              <Carousel autoPlay interval={15000} infiniteLoop={true} showArrows={false} showThumbs={false} showStatus={false}>
                {
                  content.carouselBanner.map((item, i) => (
                    <div>
                      {
                        item.embedTag ?
                          (
                            <Modal closeIcon={true}
                              trigger={
                                <Image wrapped as="a" src={item.bannerImage}
                                  style={{ cursor: "pointer" }}
                                />
                              }
                              children={
                                <>
                                  {/* <Modal.Header>
                                    {item.title}
                                    {
                                      item.referenceLink &&
                                      <a href={item.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                                        <Icon size="small" name="external" />
                                      </a>
                                    }
                                  </Modal.Header> */}
                                  <Modal.Content>
                                    {
                                      item.embedTag &&
                                      <Segment raised textAlign="center" className="embed-container">
                                        {parse(item.embedTag)}
                                      </Segment>
                                    }
                                    <Modal.Description>
                                      <Segment basic className="MkdContent" style={{ marginTop: "2em", paddingLeft: "0em" }}>
                                        {item.briefDescription && parse(marked(item.briefDescription))}
                                      </Segment>
                                    </Modal.Description>
                                  </Modal.Content>
                                </>
                              }
                            />
                          ) : (
                            <Image
                              src={item.bannerImage}
                              wrapped as="a" target="_blank"
                              href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                            />
                          )
                      }
                      {
                        <p className="legend"
                          style={{
                            left: "unset",
                            right: "0em",
                            marginLeft: "unset",
                            bottom: "5em",
                            backgroundColor: "#e09770",
                            opacity: "85%",
                            borderRadius: "unset",
                            width: "70%",
                            fontSize: "1.1em",
                            pointerEvents: "none",
                          }}
                          children={item.caption}
                        />
                      }
                    </div>
                  ))
                }
              </Carousel>
            </Container>
          }
        />
      }

      {
        isMobile &&
        <Container fluid>
          <Carousel autoPlay interval={15000} infiniteLoop={true} showArrows={false} showThumbs={false} showStatus={false}>
            {
              content.carouselBanner.map((item, i) => (
                <div>
                  {
                    item.embedTag ?
                      (
                        <Modal closeIcon={true}
                          trigger={
                            <Image wrapped as="a" src={item.bannerImage}
                              style={{ cursor: "pointer" }}
                            />
                          }
                          children={
                            <>
                              {/* <Modal.Header>
                                {item.title}
                                {
                                  item.referenceLink &&
                                  <a href={item.referenceLink} target="_blank" style={{ marginLeft: "1em" }}>
                                    <Icon size="small" name="external" />
                                  </a>
                                }
                              </Modal.Header> */}
                              <Modal.Content>
                                {
                                  item.embedTag &&
                                  <Segment raised textAlign="center"
                                    className={
                                      (isTabletSpecifically && "tablet-embed-container")
                                      || (isMobileSpecifically && "mobile-embed-container")
                                    }
                                    style={{
                                      marginTop: "3em"
                                    }}
                                    children={parse(item.embedTag)}
                                  />
                                }
                                <Modal.Description>
                                  <Segment basic className="MkdContent" style={{ marginTop: "2em", paddingLeft: "0em" }}>
                                    {item.briefDescription && parse(marked(item.briefDescription))}
                                  </Segment>
                                </Modal.Description>
                              </Modal.Content>
                            </>
                          }
                        />
                      ) : (
                        <Image
                          src={item.bannerImage}
                          wrapped as="a" target="_blank"
                          href={item.linkedFileSelfHosted || item.linkedFileOther || item.referenceLink}
                        />
                      )
                  }
                </div>
              ))
            }
          </Carousel>
        </Container>
      }

      <Container>

        <Segment basic style={{ marginTop: "4em", padding: "0em" }}>

          <Header as="h2" dividing>
            {content.introductionSection.title}
            <SocialShare config={content.introductionSection.social} float="right" />
          </Header>

          <Segment basic className="MkdContent" style={{ padding: "0em", marginTop: "2em" }}>
            {parse(marked(content.introductionSection.introduction))}
          </Segment>

        </Segment>

        <Segment basic id="news-and-featured-initiatives" style={{ marginTop: "4em", padding: "0em" }}>

          <Header as="h2" dividing>
            {content.newsAndFeaturedInitiativesSection.title}
            <SocialShare config={content.newsAndFeaturedInitiativesSection.social} float="right" />
          </Header>

          <Card.Group centered={isMobile} stackable itemsPerRow={2} style={{ marginTop: "2em" }}>
            {
              content.newsAndFeaturedInitiativesSection.newsAndFeaturedInitiatives.map(item => (
                <Card centered
                  style={{
                    marginTop: "2em",
                    padding: isMobile ? "inherit" : "1em"
                  }}
                  children={
                    <>
                      <Card.Content>
                        <Header as="h3" dividing textAlign="center">{item.title}</Header>
                        <Grid divided={!isMobile} stackable
                          style={{
                            marginTop: "1em",
                          }}
                          children={
                            <>
                              <Grid.Column width={5}
                                textAlign={isMobile && "center"}
                                children={
                                  <a
                                    target="_blank"
                                    href={item.referenceLink}
                                    children={
                                      <Image bordered centered rounded
                                        src={item.image} size="small"
                                      />
                                    }
                                  />
                                }
                              />
                              <Grid.Column width={11}
                                children={
                                  <Segment basic style={{ padding: "0em" }}>
                                    <Segment basic className="MkdContent" style={{ padding: "0em" }}>
                                      {item.briefDescription && parse(marked(item.briefDescription))}
                                    </Segment>
                                    {
                                      item.referenceLink &&
                                      <Button primary as="a" target="_blank"
                                        href={item.referenceLink}
                                        content="Read More"
                                      />
                                    }
                                    {
                                      (item.linkedFileSelfHosted || item.linkedFileOther) &&
                                      <Button primary as="a" target="_blank"
                                        href={item.linkedFileSelfHosted || item.linkedFileOther}
                                        content="Download"
                                      />
                                    }
                                  </Segment>
                                }
                              />
                            </>
                          }
                        />
                      </Card.Content>
                    </>
                  }
                />
              ))
            }
          </Card.Group>

        </Segment>

      </Container >
    </>
  )
}
